import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuthContext } from '../contexts/AuthContext'
import { logout } from '../libs/auth'
import { getErrorMessage, isSuperAdmin, lowerCase } from '../utils'
import swal from 'sweetalert'
import ficovenlogo from '../assets/logo/ficovenlogo.png'
import Axios from '../config'
import Loader from './Loader'

function Navbar() {

  const navigate = useNavigate()
  const [logoutLoading, setLogoutLoading] = useState(false)


  const {
    authState: { isAuthenticated, user },
    authDispatch
  } = useAuthContext()

  // handle logout
  const handleLogout = async () => {
    setLogoutLoading(true)
    try {
      if (localStorage.getItem('_f_user')) {
        const user = localStorage.getItem('_f_user')

        if (!user) return null
        const _user = JSON.parse(user)
        await Axios.patch('/users/logout', { refreshToken: _user.refreshToken })
      }
      localStorage.removeItem('_f_user')

      authDispatch({ type: 'LOG_OUT' })

      swal('Great', 'Logout Successful', 'success').then(() => {
        // Once the swal dialog is closed, redirect to the home page
        setLogoutLoading(false)
        navigate('/')
      })
    } catch (error) {
      // console.log('header Logout error', error)
      setLogoutLoading(false)
      swal('Oops', getErrorMessage(error), 'error')
    }
  }

  return (
    <>
    {/* Full-screen modal for loading */}
    {logoutLoading && (
        <div className="modal-overlay">
          <div className="loader-container">
            {/* <Loader /> */}
            <div className="spinner-border" role="status" style={{ 
            borderColor: 'white', // White stroke
            boxShadow: '0 0 10px white' // White background shadow
          }}>
            <div className="spinner-border" style={{ 
              color: '#006500', 
              animation: 'spin 1s linear infinite' // Ensure the inner spinner spins
            }}></div>
            <span className="sr-only">Loading...</span>
          </div>
          </div>
        </div>
      )}
      <nav className='navbar navbar-expand-md navbar-dark bg-dark'>
        <div className='container-fluid'>
          <Link className='navbar-brand' to={isAuthenticated ? '/admin' : '/'}>
            <img src={ficovenlogo} alt='NIN | Ficoven' className='navbar-logo' width="150px" />
          </Link>
          <button
            className='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#navbarNav'
            aria-controls='navbarNav'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarNav'>
            <ul className='navbar-nav ml-auto'>
              {isAuthenticated ? (
                <li className='nav-item dropdown'>
                  <a
                    className='nav-link dropdown-toggle'
                    href='#'
                    id='navbarDropdownMenuLink'
                    role='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <i className='fa fa-user' style={{
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                      whiteSpace: 'normal'
                    }}></i> {user.name}
                  </a>
                  <div
                    className='dropdown-menu dropdown-menu-right'
                    aria-labelledby='navbarDropdownMenuLink'
                  >
                    {isAuthenticated && (
                      <Link className='dropdown-item' to='/admin'>
                        Home
                      </Link>
                    )}
                    <Link className='dropdown-item' to='/profile'>
                      Profile
                    </Link>
                    {isSuperAdmin.includes(lowerCase(user.role)) && (
                      <Link className='dropdown-item' to='/register'>
                        Add New Admin
                      </Link>
                    )}
                    <Link
                      className='dropdown-item'
                      to='#'
                      onClick={e => handleLogout(e)}
                    >
                      Logout
                    </Link>
                  </div>
                </li>
              ) : (
                <li className='nav-item dropdown'>
                  <a
                    className='nav-link dropdown-toggle'
                    href='#'
                    id='navbarDropdownMenuLink'
                    role='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <i className='fa fa-user'></i> {user.name}
                  </a>
                  <div
                    className='dropdown-menu dropdown-menu-right'
                    aria-labelledby='navbarDropdownMenuLink'
                  >
                    <Link className='dropdown-item' to='/login'>
                      Login
                    </Link>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar
