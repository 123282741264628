import React, { useRef, useState } from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { getErrorMessage, validateEmail } from '../utils'
import Axios from '../config'
import swal from 'sweetalert'
import Loader from './Loader'

const url =
  'https://tech.us2.list-manage.com/subscribe?u=b7f10bab48a58f57296c47379&id=1d02ee4777'

// simplest form (only email)
const SimpleForm = () => <MailchimpSubscribe url={url} />

function Footer() {
  const [email, setEmail] = useState('')
  const [footerNewsletterLoading, setFooterNewsletterLoading] = useState(false)
  const [emailError, setEmailError] = useState('')
  const emailInputRef = useRef(null)

  const handleEmailChange = e => {
    setEmail(e.target.value)
    setEmailError('')
  }

  const handleSubscribe = async e => {
    e.preventDefault()
    setFooterNewsletterLoading(true)
    try {
      let isError = false
      if (email.trim() === '') {
        setEmailError('Please enter email address')
        emailInputRef.current.focus() // Focus on the input element with the validation error
        setFooterNewsletterLoading(false)
        return (isError = true)
      }

      if (!validateEmail(email.trim())) {
        setEmailError('Please input a valid email address')
        emailInputRef.current.focus() // Focus on the input
        setFooterNewsletterLoading(false)

        return (isError = true)
      }

      const { data } = await Axios.post('/subscribers/subscribe', { email })

      if (data) {
        swal('Great', 'Newsletter Subscription Successful', 'success').then(
          () => {
            // Once the swal dialog is closed, empty email input
            return setEmail('')
          }
        )
      }

      setFooterNewsletterLoading(false)
    } catch (error) {
      swal('Oops', getErrorMessage(error), 'error')
      setFooterNewsletterLoading(false)
    }
  }
  return (
    <div className='mt-5 footer'>
      <footer
        className='text-center text-lg-start text-dark shadow-lg'
        style={{ backgroundColor: '#ECEFF1', borderRadius: '50px 50px 0 0 ' }}
      >
        <section className=''>
          <div className='container text-center text-md-start mt-5'>
            <div className='row mt-3 pt-5'>
              <div className='col-md-3 col-lg-4 col-xl-3 mx-auto mb-4 '>
                <h6 className='text-uppercase fw-bold'>ABOUT US</h6>
                <hr
                  className='mb-4 mt-0 d-inline-block mx-auto'
                  style={{
                    width: '60px',
                    backgroundColor: 'rgb(142, 223, 142)',
                    height: '2px'
                  }}
                />
                <p>
                  Ficoven is responsible for NIN enrolment of Nigerians in
                  diaspora.
                </p>
              </div>

              <div className='col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4'>
                <h6 className='text-uppercase fw-bold'>Contact</h6>
                <hr
                  className='mb-4 mt-0 d-inline-block mx-auto'
                  style={{
                    width: '60px',
                    backgroundColor: 'rgb(142, 223, 142)',
                    height: '2px'
                  }}
                />
                <p>
                  <i className='fas fa-home mr-3'></i>Head Office: No 1 Nwadiolu
                  Street, Aboru, Iyana Ipaja, Lagos, Nigeria.
                </p>
                <p>
                  <i className='fas fa-envelope mr-3'></i> Info@ficoven.com
                </p>
                <p>
                  <i className='fas fa-phone mr-3'></i>{' '}
                  <a href='tel:+447723926516'> +447723926516</a>
                </p>
                <p>
                  <i className='fas fa-phone mr-3'></i>{' '}
                  <a href='tel:+447555767355'> +447555767355</a>
                </p>
                <p>
                  <i className='fas fa-phone mr-3'></i>{' '}
                  <a href='tel:+13477662711'> +13477662711</a>
                </p>
              </div>

              <div className='col-md-3 col-lg-2 col-xl-2 mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold'>Useful links</h6>
                <hr
                  className='mb-4 mt-0 d-inline-block mx-auto'
                  style={{
                    width: '60px',
                    backgroundColor: 'rgb(142, 223, 142)',
                    height: '2px'
                  }}
                />
                <p>
                  <a
                    href='https://nin.ficoven.com/terms-and-conditions/'
                    className='text-dark'
                  >
                    T&C's
                  </a>
                </p>
                <p>
                  <a
                    href='https://nin.ficoven.com/privacy-policy/'
                    className='text-dark'
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>

              <div className='col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4 mailChimp'>
                <h6 className='text-uppercase fw-bold'>Newsletter</h6>
                <hr
                  className='mb-4 mt-0 d-inline-block mx-auto'
                  style={{
                    width: '60px',
                    backgroundColor: 'rgb(142, 223, 142)',
                    height: '2px'
                  }}
                />
                {/* <MailchimpSubscribe
                  url={url}
                  render={({ subscribe, status, message }) => (
                    <div>
                      <SimpleForm
                        onSubmitted={(formData) => subscribe(formData)}
                      />
                      {status === "sending" && (
                        <div style={{ color: "black" }}>sending...</div>
                      )}
                      {status === "error" && (
                        <div
                          style={{ color: "red" }}
                          dangerouslySetInnerHTML={{ __html: message }}
                        />
                      )}
                      {status === "success" && (
                        <div style={{ color: "green" }}>Subscribed !</div>
                      )}
                    </div>
                  )}
                /> */}
                <form>
                  <h5>Subscribe to our newsletter</h5>
                  <p style={{ textAlign: 'justify' }}>
                    Monthly digest of what's new and exciting from us.
                  </p>
                  <>
                    <label for='newsletter' className='visually-hidden'>
                      Email address
                    </label>
                    <div className='input-group has-validation'>
                      <input
                        id='newsletter'
                        type='email'
                        className={`form-control ${emailError ? 'is-invalid' : ''
                          } ${email ? 'is-valid' : ''}`}
                        placeholder='Email address'
                        required
                        onChange={e => {
                          handleEmailChange(e)
                        }}
                        ref={emailInputRef}
                      />
                      <div className='invalid-feedback'>{emailError}</div>
                    </div>
                    {footerNewsletterLoading && <Loader />}
                    <button
                      className='btn btn-success shadow'
                      onClick={e => handleSubscribe(e)}
                    >
                      Subscribe
                    </button>
                  </>
                </form>
              </div>
            </div>
          </div>
        </section>
        <div
          className='text-center p-3'
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
        >
          {new Date().getFullYear()} ©  Ficoven Investments LTD - Developed by
          <a
            className='text-dark'
            href='https://visitdominicazuka.com/'
            target='_blank'
            rel='noreferrer'
          >
            {' '}
            Omimek Technology
          </a>
        </div>
      </footer>
    </div>
  )
}

export default Footer
