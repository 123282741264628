import './App.css'
import React, { useEffect, lazy, Suspense } from 'react'
import Navbar from './components/Navbar'
import { Routes, Route, Navigate } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
// import 'antd/dist/antd.css'
import ProtectedRoute from './layouts/ProtectedRoute'
import { useAuthContext } from '../src/contexts/AuthContext'
import { useAutoLogout } from './libs/auth/index.js'

// Import DataTables dependencies
import '../node_modules/datatables.net/js/dataTables.min.js'
import '../node_modules/datatables.net-bs4/js/dataTables.bootstrap4.min.js'
import '../node_modules/datatables.net-buttons/js/buttons.html5.min.js'
import '../node_modules/datatables.net-buttons/js/buttons.print.min.js'
import '../node_modules/datatables.net-buttons/js/dataTables.buttons.min.js'
import '../node_modules/datatables.net-buttons/js/buttons.colVis.min.js'
import '../node_modules/datatables.net-buttons-bs4/js/buttons.bootstrap4.min.js'
import '../node_modules/datatables.net-responsive-bs4/js/responsive.bootstrap4.min.js'
import '../node_modules/datatables.net-dt/js/dataTables.dataTables.min.js'
import '../node_modules/datatables.net-responsive/js/dataTables.responsive.min.js'

// Lazy-loaded components
const HomeScreen = lazy(() => import('./views/frontend/HomeScreen'))
const UserScreen = lazy(() => import('./views/frontend/book/UserScreen'))
const RegisterScreen = lazy(() => import('./views/auth/RegisterScreen'))
const LoginScreen = lazy(() => import('./views/auth/LoginScreen'))
const AllBookings = lazy(() => import('./views/backend/booking/AllBookings'))
const AllServices = lazy(() => import('./views/backend/service/AllServices'))
const AllUsers = lazy(() => import('./views/backend/user/AllUsers'))
const AllCenters = lazy(() => import('./views/backend/center/AllCenters'))
const ProfileScreen = lazy(() => import('./views/auth/ProfileScreen.js'))
const Dashboard = lazy(() => import('./views/backend/Dashboard.js'))
const AddCenter = lazy(() => import('./views/backend/center/AddCenter'))
const AddService = lazy(() => import('./views/backend/service/AddService'))
const BookingDetails = lazy(() =>
  import('./views/backend/booking/BookingDetails.js')
)
const EditService = lazy(() => import('./views/backend/service/EditService'))
const EditCenter = lazy(() => import('./views/backend/center/EditCenter'))
const AllPayments = lazy(() => import('./views/backend/payment/AllPayments'))
const PaymentDetails = lazy(() =>
  import('./views/backend/payment/PaymentDetails.js')
)
const EditUserProfile = lazy(() =>
  import('./views/backend/user/EditUserProfile')
)
const TestScreen = lazy(() => import('./views/frontend/TestScreen'))
const Analytics = lazy(() => import('./views/backend/analytic/Analytics'))
const BookingScreen = lazy(() => import('./views/frontend/book/BookingScreen'))
const AllSubscribers = lazy(() => import('./views/backend/newsletter/AllSubscribers'))

function App () {
  useAutoLogout()

  const {
    authState: { isAuthenticated }
  } = useAuthContext()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Suspense
      fallback={
        <div className='col text-center p-5'>
          <LoadingOutlined style={{ fontSize: 50, color: 'green' }} spin />
        </div>
      }
    >
      <div className='App'>
        <Navbar />
        <Routes>
          <Route path='/' element={<HomeScreen />} />
          <Route path='/login' element={<LoginScreen />} />
          <Route path='/book/:serviceid/:date' element={<BookingScreen />} />
          <Route path='/user/details' element={<UserScreen />} />
          <Route path='/test-link' element={<TestScreen />} />
          <Route
            path='/edit_service/:details'
            element={
              <ProtectedRoute>
                <EditService />
              </ProtectedRoute>
            }
          />
          <Route
            path='/edit_user/:details'
            element={
              <ProtectedRoute>
                <EditUserProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path='/edit_center/:details'
            element={
              <ProtectedRoute>
                <EditCenter />
              </ProtectedRoute>
            }
          />
          <Route
            path='/payments/:details'
            element={
              <ProtectedRoute>
                <PaymentDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path='/users/:user'
            element={
              <ProtectedRoute>
                <BookingDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path='/register'
            element={
              <ProtectedRoute>
                <RegisterScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path='/bookings'
            element={
              <ProtectedRoute>
                <AllBookings />
              </ProtectedRoute>
            }
          />
          <Route
            path='/analytics'
            element={
              <ProtectedRoute>
                <Analytics />
              </ProtectedRoute>
            }
          />
          <Route
            path='/profile'
            element={
              <ProtectedRoute>
                <ProfileScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path='/admin'
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path='/services'
            element={
              <ProtectedRoute>
                <AllServices />
              </ProtectedRoute>
            }
          />
          <Route
            path='/users'
            element={
              <ProtectedRoute>
                <AllUsers />
              </ProtectedRoute>
            }
          />
          <Route
            path='/enrolment-centers'
            element={
              <ProtectedRoute>
                <AllCenters />
              </ProtectedRoute>
            }
          />
          <Route
            path='/payments'
            element={
              <ProtectedRoute>
                <AllPayments />
              </ProtectedRoute>
            }
          />

          <Route
            path='/newsletter-subscribers'
            element={
              <ProtectedRoute>
                <AllSubscribers />
              </ProtectedRoute>
            }
          />
          <Route
            path='/add-service'
            element={
              <ProtectedRoute>
                <AddService />
              </ProtectedRoute>
            }
          />
          <Route
            path='/add-center'
            element={
              <ProtectedRoute>
                <AddCenter />
              </ProtectedRoute>
            }
          />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      </div>
    </Suspense>
  )
}

export default App
